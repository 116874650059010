import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {useEffect, useRef, useState} from "react";
import {request} from "../../../../utils/request";
import SuccessBadge from "../../../../components/badges/success-badge";
import {withRouter} from "../../../../utils/navigation";
import DangerBadge from "../../../../components/badges/danger-badge";
import WarningBadge from "../../../../components/badges/warning-badge";
import GrayBadge from "../../../../components/badges/gray-badge";
import CreateWhiteLabelModal from "../../../../modals/admin/tools/create-white-label-modal";
import {setupReduxConnection} from "../../../../redux";
import WhiteLabelBadge from "../../../../features/admin/whitelabel/white-label-badge";

function WhiteLabeledAppsPage(props) {
    const {whiteLabels} = props.admin;
  let createWhiteLabelModal= useRef(null);

  useEffect(() => {
    props.loadWhiteLabels()
  }, []);

  return (
    <div>
      <CreateWhiteLabelModal
        ref={e => createWhiteLabelModal=e}
      />

      <PageHeadings label={"White Labeled"} />

      <Card
        label={"Apps"}
        button={{
          label: "+ Create",
          onClick: () => createWhiteLabelModal.open(),
        }}
      >
        <Table
          data={whiteLabels}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {props.router.navigate(row.UNIQUE_ID)}
            },
          ]}
          columns={[
            {
              label: "Franchise Name",
              value: "NAME",
            },
            {
              label: "Status",
              value: "STATUS",
              format: (value) => <WhiteLabelBadge status={value} />,
            },
          ]}
        />
      </Card>
    </div>
  );
}

export default setupReduxConnection(["admin"])(withRouter(WhiteLabeledAppsPage));
