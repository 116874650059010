import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {setupReduxConnection} from "../../../redux";
import {loadWhiteLabels} from "../../../redux/admin";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import FormDropZone from "../../../components/form-drop-zone";

class EditWhiteLabelInformationModal extends Component {
  state = {whiteLabel: {}};

  open(whiteLabel = null) {
    this.setState({whiteLabel}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveWhiteLabel = async ({
    appIcon,
    landingIcon,
    useAppAsLanding,
  }) => {
    const payload = {
      APP_ICON: appIcon,
      LANDING_ICON: useAppAsLanding === "1" ? appIcon : landingIcon,
    };

    console.log(landingIcon);

    await request(`whitelabel/${this.state.whiteLabel.UNIQUE_ID}`, "PATCH", payload);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {
      APP_ICON,
      LANDING_ICON,
    } = this.state.whiteLabel;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Edit App Icons"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.saveWhiteLabel}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({})}
          initialValues={{
            appIcon: APP_ICON,
            landingIcon: LANDING_ICON,
            useAppAsLanding: LANDING_ICON !== APP_ICON ? "0" : "1",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            const {useAppAsLanding} = values;

            return (
              <form onSubmit={handleSubmit}>
                <ImageFormDropZone
                  label={"App Icon (Transparent Background)"}
                  options={formikOptions}
                  name="appIcon"
                />

                <FormBoolean
                  label={"Use same icon for landing page?"}
                  name={"useAppAsLanding"}
                  options={formikOptions}
                />

                {useAppAsLanding === "0" && (
                  <ImageFormDropZone
                    label={"Loading Icon (Transparent Background)"}
                    name={"landingIcon"}
                    options={formikOptions}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(
  withRouter(EditWhiteLabelInformationModal)
);
