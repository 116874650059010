import {
  FormBoolean,
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TicketTypeDropdown from "../../../dropdowns/sales/ticket-type-dropdown";

class OrderSystemModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {
      deliveryEnabled,
      kioskEnabled,
      requireCashDrawer,
      taxWithholding,
      defaultCashAmount,
      locationBasedCashDrawers,
      invoiceOrderType,
      coupons,
      cashDeliveryEnabled,
      closeoutOverShortEnabled,
      closeoutOverShortAmount,
      cashDrawerVerificationEnabled,
      showNoTaxIconPos,
    } = values;

    let payload = {
      DELIVERY_ENABLED: deliveryEnabled,
      KIOSK_ENABLED: kioskEnabled,
      REQUIRE_CASH_DRAWER: requireCashDrawer,
      TAX_WITHHOLDING: taxWithholding,
      DEFAULT_CASH_AMOUNT: decimalToDollars(defaultCashAmount),
      LOCATION_BASED_CASH_DRAWERS: locationBasedCashDrawers,
      INVOICE_ORDER_TYPE: invoiceOrderType,
      // SHOW_COUPON_CHECKOUT: coupons,
      CASH_DELIVERY_ENABLED: cashDeliveryEnabled,
      CLOSEOUT_OVER_SHORT_ENABLED: closeoutOverShortEnabled,
      CLOSEOUT_OVER_SHORT_AMOUNT: decimalToDollars(closeoutOverShortAmount),
      CASH_DRAWER_VERIFICATION_ENABLED: cashDrawerVerificationEnabled,
      SHOW_NO_TAX_ICON_POS: showNoTaxIconPos,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {
      DELIVERY_ENABLED,
      KIOSK_ENABLED,
      REQUIRE_CASH_DRAWER,
      TAX_WITHHOLDING,
      DEFAULT_CASH_AMOUNT,
      LOCATION_BASED_CASH_DRAWERS,
      INVOICE_ORDER_TYPE,
      // SHOW_COUPON_CHECKOUT,
      CASH_DELIVERY_ENABLED,
      CLOSEOUT_OVER_SHORT_ENABLED,
      CLOSEOUT_OVER_SHORT_AMOUNT,
      CASH_DRAWER_VERIFICATION_ENABLED,
      SHOW_NO_TAX_ICON_POS,
    } = this.props.shop.settings;

    let {TAX_RATE} = this.props.shop.location;

    return (
      <Modal
        label="Order System Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            deliveryEnabled: DELIVERY_ENABLED,
            kioskEnabled: KIOSK_ENABLED,
            requireCashDrawer: REQUIRE_CASH_DRAWER,
            taxWithholding: TAX_WITHHOLDING,
            // coupons: SHOW_COUPON_CHECKOUT,
            defaultCashAmount: toDollars(DEFAULT_CASH_AMOUNT),
            locationBasedCashDrawers: LOCATION_BASED_CASH_DRAWERS,
            invoiceOrderType: INVOICE_ORDER_TYPE,
            cashDeliveryEnabled: CASH_DELIVERY_ENABLED,
            closeoutOverShortEnabled: CLOSEOUT_OVER_SHORT_ENABLED,
            closeoutOverShortAmount: toDollars(CLOSEOUT_OVER_SHORT_AMOUNT),
            cashDrawerVerificationEnabled: CASH_DRAWER_VERIFICATION_ENABLED,
            showNoTaxIconPos: SHOW_NO_TAX_ICON_POS,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="deliveryEnabled"
                  label="Delivery Enabled"
                  options={formikOptions}
                />

                <FormBoolean
                  name="kioskEnabled"
                  label="Kiosk Enabled"
                  options={formikOptions}
                />

                <FormBoolean
                  name="requireCashDrawer"
                  label="Require Cash Drawer for Charge"
                  options={formikOptions}
                />

                <FormInput
                  name={"defaultCashAmount"}
                  label={"Cash Drawer Starting Amount"}
                  options={formikOptions}
                />

                <FormBoolean
                  name="locationBasedCashDrawers"
                  label="Share Cash Drawer Between Devices"
                  options={formikOptions}
                />

                <FormBoolean
                  name="taxWithholding"
                  label="Tax Withholding"
                  options={formikOptions}
                />

                <TicketTypeDropdown
                  options={formikOptions}
                  name="invoiceOrderType"
                  label="Invoicing Order Type"
                />

                <FormBoolean
                  options={formikOptions}
                  name="cashDeliveryEnabled"
                  label="Enable Delivery Pay at Door"
                  tooltip="When enabled, customers can checkout without payment and pay at their door when the delivery is completed."
                />

                <FormBoolean
                  options={formikOptions}
                  name="closeoutOverShortEnabled"
                  label="Require PIN on Close-Out Over/Short"
                  tooltip="When enabled, an employee with the permission to close drawer on discrepancy must enter their PIN on cash drawer close."
                />

                <FormInput
                  options={formikOptions}
                  name="closeoutOverShortAmount"
                  label="Close-Out Over/Short Amount"
                  tooltip="This is the maximum allowable discrepancy between cash amount in the drawer and cash amount expected on closing the cash drawer."
                />

                <FormBoolean
                  options={formikOptions}
                  name="cashDrawerVerificationEnabled"
                  label="Include Cash Drawer Verification"
                  tooltip="When enabled, an employee with this permission will be able to verify final cash drawer amount."
                />

                <FormBoolean
                  options={formikOptions}
                  name="showNoTaxIconPos"
                  label="Show Tax Disabled Icon on POS"
                  tooltip="Enable this option to display an icon on the POS indicating when tax is not applied to an item."
                />

                {/*<FormBoolean*/}
                {/*  name="coupons"*/}
                {/*  label="Coupons on the Point of Sale"*/}
                {/*  options={formikOptions}*/}
                {/*/>*/}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  OrderSystemModal
);
