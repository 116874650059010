import React, {Component} from "react";

class InsurancePage extends Component {
  state = {payoutData: null};

  render() {
    return (
      <iframe
        title="My iFrame"
        src="https://dripos.prod.authenticinsurance.com/"
        style={{width: "100%", height: "80vh", border: "none"}}
      />
    );
  }
}

export default InsurancePage;
