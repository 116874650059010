import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import OrderSystemModal from "../../../modals/shop/shop-settings/order-system-modal";
import ProcessingFeesModal from "../../../modals/shop/shop-settings/processing-fees-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";

class OrderSystemTab extends Component {
  state = {orderTypes: null};

  componentDidMount() {
    request("types/order", "GET").then((orderTypes) => this.setState({orderTypes}));
  }

  render() {
    let {
      DELIVERY_ENABLED,
      KIOSK_ENABLED,
      REQUIRE_CASH_DRAWER,
      PASS_PROCESSING_FEES_MOBILE,
      PASS_PROCESSING_FEES_POS,
      TAX_WITHHOLDING,
      DEFAULT_CASH_AMOUNT,
      LOCATION_BASED_CASH_DRAWERS,
      INVOICE_ORDER_TYPE,
      CLOSEOUT_OVER_SHORT_ENABLED,
      CLOSEOUT_OVER_SHORT_AMOUNT,
      CASH_DRAWER_VERIFICATION_ENABLED,
      SHOW_NO_TAX_ICON_POS,
      // SHOW_COUPON_CHECKOUT
      CASH_DELIVERY_ENABLED,
    } = this.props.shop.settings;

    const {orderTypes} = this.state;

    return (
      <div>
        <OrderSystemModal ref={(e) => (this.orderSystemModal = e)} />

        <ProcessingFeesModal ref={(e) => (this.processingFeesModal = e)} />

        <TwoColumnList
          label="Order System"
          description="Configure order system settings for your shop"
          data={[
            {
              label: "In-House Delivery Enabled",
              value: DELIVERY_ENABLED,
              type: "bool",
              tooltip:
                "If this is set to enabled, customers will be able to place orders on the Dripos order website and mobile app for in-house delivery.",
            },
            {
              label: "Kiosk Enabled",
              value: KIOSK_ENABLED,
              type: "bool",
              tooltip:
                "If this is set to enabled, kiosk features will be available on your POS devices.",
            },
            {
              label: "Require Cash Drawer for Charge",
              value: REQUIRE_CASH_DRAWER,
              type: "bool",
              tooltip:
                "If this is set to yes, a cash drawer will need to be opened in order to make any charge.",
            },
            {
              label: "Cash Drawer Starting Amount",
              value: `${toDollars(DEFAULT_CASH_AMOUNT, true)}`,
              tooltip:
                "This is the default starting cash amount when a new cash drawer is opened.",
            },
            {
              label: "Share Cash Drawer Between Devices",
              value: LOCATION_BASED_CASH_DRAWERS,
              type: "bool",
              tooltip:
                "If this is set to yes, an open cash drawer will be shared across devices within your location. If this is set to no, cash drawers will be specific to each device.",
            },
            {
              label: "Tax Withholding",
              value: TAX_WITHHOLDING,
              type: "bool",
              tooltip:
                "If this is set to yes, taxes will be withheld on each purchase for tax payouts that are separate from the regular payouts.",
            },
            {
              label: "Invoicing Order Type",
              value:
                orderTypes && orderTypes.length > 0
                  ? orderTypes.find((type) => type.ID + "" === INVOICE_ORDER_TYPE)
                      ?.NAME ?? orderTypes[0].NAME
                  : "",
              type: "string",
              tooltip: "Select which order type under which your invoices will appear.",
            },
            {
              label: "Enable Delivery Pay at Door",
              value: CASH_DELIVERY_ENABLED,
              type: "bool",
              tooltip:
                "If this is set to enabled, customers can checkout without payment and pay at their door when the order is delivered.",
            },
            {
              label: "Require PIN on Close-Out Over/Short",
              value: CLOSEOUT_OVER_SHORT_ENABLED,
              type: "bool",
              tooltip:
                "If this is set to enabled, an employee with the permission to close drawer on discrepancy must enter their PIN on cash drawer close.",
            },
            {
              label: "Close-Out Over/Short Amount",
              value:
                CLOSEOUT_OVER_SHORT_ENABLED === "1"
                  ? `${toDollars(CLOSEOUT_OVER_SHORT_AMOUNT, true)}`
                  : "N/A",
              tooltip:
                "This is the maximum allowable discrepancy between cash amount in the drawer and cash amount expected on closing the cash drawer.",
            },
            {
              label: "Include Cash Drawer Verification",
              value: CASH_DRAWER_VERIFICATION_ENABLED,
              type: "bool",
              tooltip:
                "If this is set to enabled, an employee with permissions will be able to verify final cash drawer amount.",
            },
            {
              label: "Show Tax Disabled Icon on POS",
              value: SHOW_NO_TAX_ICON_POS,
              type: "bool",
              tooltip:
                "Enable this option to display an icon on the POS indicating when tax is not applied to an item.",
            },
            // {
            //   label: "Coupons on Point of Sale",
            //   value: SHOW_COUPON_CHECKOUT,
            //   type: "bool",
            //   tooltip:
            //     "If enabled you will have the ability to attach coupons on the Point of Sale",
            // },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.orderSystemModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Processing Fees"
          description="If enabled, these settings will apply a surcharge labeled “Processing” to each card transaction that is not greater than your cost of acceptance. Prior to activation, please ensure full compliance with state laws and card merchant regulations. Enable at your own discretion."
          data={[
            {
              label: "Pass Processing on Online & Mobile Ordering",
              value: PASS_PROCESSING_FEES_MOBILE,
              type: "bool",
              tooltip:
                "If this is set to yes, processing fees on all POS orders will be passed onto the customer.",
            },
            {
              label: "Pass Processing on POS Ordering",
              value: PASS_PROCESSING_FEES_POS,
              type: "bool",
              tooltip:
                "If this is set to yes, processing fees on all website and mobile orders will be passed onto the customer.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.processingFeesModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(OrderSystemTab);
