import ShopPage from "../../pages/shop/shop-page";
import SalesTrackerPage from "../../pages/admin/trackers/sales-tracker-page";
import SuccessTrackerPage from "../../pages/admin/trackers/success-tracker-page";
import ProductTrackerPage from "../../pages/admin/trackers/product-tracker-page";
import PayrollTrackerPage from "../../pages/admin/trackers/payroll-tracker-page";
import TokenRequestsPage from "../../pages/admin/trackers/token-requests-page";
import SuccessGoalPage from "../../pages/admin/success/success-goal-page";
import ProductBugsPage from "../../pages/admin/product/product-bugs-page";
import PayrollSuccessPage from "../../pages/success/payroll-success-page";
import AdminPage from "../../pages/admin/admin-page";
import RecordsSideNav from "../../layouts/side-navs/admin/records-side-nav";
import LocationsPage from "../../pages/admin/records/locations-page";
import CompaniesPage from "../../pages/admin/records/companies-page";
import FranchisesPage from "../../pages/admin/records/franchises-page";
import LocationPage from "../../pages/admin/records/location-page";
import ToolsSideNav from "../../layouts/side-navs/admin/tools-side-nav";
import AppVersionsPage from "../../pages/admin/tools/deployment/reader-versions-page";
import ReaderGroupsPage from "../../pages/admin/tools/deployment/reader-groups-page";
import ReaderPlansPage from "../../pages/admin/tools/deployment/reader-plans-page";
import ReaderLocationsPage from "../../pages/admin/tools/deployment/reader-locations-page";
import ReadersPage from "../../pages/admin/tools/deployment/readers-page";
import CompanyPage from "../../pages/admin/records/company-page";
import TipPayoutToolPage from "../../pages/admin/tools/tip-payout-tool-page";
import BarcodeImportPage from "../../pages/success/barcode-import";
import MapPage from "../../pages/map/map-page";
import WhiteLabeledAppsPage from "../../pages/admin/tools/whitelabel/white-labeled-apps-page";
import WhiteLabelPage from "../../pages/admin/tools/whitelabel/white-label-page";

export const ADMIN_PAGE_ROUTES = [
  {
    routes: [
      {
        path: "track/map",
        element: <MapPage />,
        ranks: ["ADMIN"],
      },
      {
        path: "track/sales",
        element: <SalesTrackerPage />,
        ranks: ["ADMIN"],
        header: false,
      },
      {
        path: "track/product",
        element: <ProductTrackerPage />,
        ranks: ["ADMIN"],
        header: false,
      },
      {
        path: "track/success",
        element: <SuccessTrackerPage />,
        ranks: ["ADMIN"],
        header: false,
      },
      {
        path: "track/payroll",
        element: <PayrollTrackerPage />,
        ranks: ["ADMIN"],
        header: false,
      },
      {
        path: "product/bugs",
        element: <ProductBugsPage />,
        ranks: ["ADMIN"],
        header: false,
      },
      {
        path: "success/goal",
        element: <SuccessGoalPage />,
        ranks: ["ADMIN"],
        header: false,
      },

      {
        path: "tore",
        element: <TokenRequestsPage />,
        ranks: ["ADMIN"],
      },

      {
        path: "success/payroll",
        element: <PayrollSuccessPage />,
        ranks: ["ADMIN"],
      },
      {path: "barcode/import", element: <BarcodeImportPage />, ranks: ["ADMIN"]},
      {
        live: true,
        path: "tip-payouts",
        element: <TipPayoutToolPage />,
        ranks: ["ADMIN"],
      },
      {
        path: "/",
        element: <AdminPage />,
        ranks: ["ADMIN"],
      },
      {
        path: "whitelabel",
        element: <WhiteLabeledAppsPage />,
        ranks: ["ADMIN"],
      },
      {
        path: "whitelabel/:UNIQUE_ID",
        element: <WhiteLabelPage />,
        ranks: ["ADMIN"]
      }
    ],
  },
  {
    side: <RecordsSideNav />,
    routes: [
      {
        live: true,
        path: "locations",
        element: <LocationsPage />,
      },
      {
        live: true,
        path: "location/:ID",
        element: <LocationPage />,
      },
      {
        live: true,
        path: "company/:ID",
        element: <CompanyPage />,
      },
      {
        live: true,
        path: "companies",
        element: <CompaniesPage />,
      },
      {
        live: true,
        path: "franchises",
        element: <FranchisesPage />,
      },
    ],
  },
  {
    side: <ToolsSideNav />,
    routes: [
      {
        live: true,
        path: "reader/versions",
        element: <AppVersionsPage />,
      },
      {
        live: true,
        path: "reader/groups",
        element: <ReaderGroupsPage />,
      },
      {
        live: true,
        path: "reader/plans",
        element: <ReaderPlansPage />,
      },
      {
        live: true,
        path: "reader/locations",
        element: <ReaderLocationsPage />,
      },
      {
        live: true,
        path: "readers",
        element: <ReadersPage />,
      },
    ],
  },
];
