import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {setupReduxConnection} from "../../../redux";
import {loadWhiteLabels} from "../../../redux/admin";

class CreateWhiteLabelModal extends Component {
  state = {group: null};

  open(group = null) {
    this.setState({group}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  createWhiteLabel = async ({companyId, packageIdentifier}) => {
    await request(`whitelabel/${companyId}`, "POST", {
      PACKAGE_IDENTIFIER: packageIdentifier,
    });

    await this.props.loadWhiteLabels();

    this.modal.close();
  };

  render() {
    const {group} = this.state;

    return (
      <Modal
        buttonLabel={"Create"}
        label={"Create White-Label"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.createWhiteLabel}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            companyId: Yup.string().required("Company is required"),
            packageIdentifier: Yup.string().required("Package identifier is required"),
          })}
          initialValues={{}}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <CompanyDropdown
                  name={"companyId"}
                  label={"Company"}
                  options={formikOptions}
                />

                <FormInput
                  label={"Package Identifier"}
                  name={"packageIdentifier"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(withRouter(CreateWhiteLabelModal));
