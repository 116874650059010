import React, {Component} from "react";
import Header from "./header/header";
import {request} from "../utils/request";
import {getStore} from "../redux";
import Loading from "@frostbyte-technologies/frostbyte-tailwind/dist/components/loading";
import {withRouter} from "../utils/navigation";
import {updateShop} from "../redux/shop";
import {updateUser} from "../redux/user";
import SwitchLocationModal from "../modals/shop/switch-location-modal";
import {setupSocketHandler} from "../listeners/socket-handler";
import NotificationService from "./services/notification-service";
import AlertService from "./services/alert-service";
import moment from "moment-timezone";
import Announcement from "../components/announcement";
import ChatBubble from "../components/chat-bubble";
import * as Sentry from "@sentry/react";
import {loadConversations} from "../redux/chat";
import {loadSupply} from "../redux/supply";
import {loadMenu} from "../redux/menu";

let momentBusinessDays = require("moment-business-days");

class LayoutIndex extends Component {
  state = {isLoading: true, isSwitching: false};

  async componentDidMount() {
    let url = "dashboard/valid/v3?";
    let currentLocation = localStorage.getItem("CURRENT_LOCATION");
    if (currentLocation !== null) {
      url += "location=" + currentLocation;
    }

    try {
      let payload = await request(url, "GET", null);

      let {
        permissions,
        account,
        location,
        settings,
        ranks,
        companySettings,
        taxRates,
        locations,
        giftCardImages,
        employee,
        companyLocations,
        payrollGroup,
        accountingGroup,
        cashAccountGroup,
        showDashboardHub,
        employees,
        roles,
        departments,
        userGuideEntries,
      } = payload;
      if (!permissions || permissions.length === 0) {
        // this.props.router.navigate("/403");
        // this.setState({noPermissions: true});
        // console.log(
        //   "Error: Insufficient Permissions. Please contact your manager to grant you admin access."
        // );
      }

      moment.tz.setDefault(settings.TIMEZONE);

      //Banking Holidays for 2022
      let holidays = {
        2022: {
          newYears: "01-01",
          mlk: "01-18",
          presidents: "02-21",
          memorialDay: "05-30",
          juneteenth: "06-19",
          july4th: "07-04",
          laborDay: "09-05",
          columbus: "10-10",
          veterans: "11-11",
          thanksgiving: "11-24",
          christmas: "12-26",
        },
        2023: {
          newYears: "01-02",
          mlk: "01-16",
          presidents: "02-20",
          memorialDay: "05-29",
          juneteenth: "06-19",
          july4th: "07-04",
          laborDay: "09-04",
          columbus: "10-09",
          veterans: "11-10",
          thanksgiving: "11-23",
          christmas: "12-25",
        },
        2024: {
          newYears: "01-01",
          mlk: "01-15",
          presidents: "02-19",
          memorialDay: "05-27",
          juneteenth: "06-19",
          july4th: "07-04",
          laborDay: "09-02",
          columbus: "10-14",
          veterans: "11-11",
          thanksgiving: "11-28",
          christmas: "12-25",
        },
      };

      momentBusinessDays.updateLocale("us", {
        holidays: Object.keys(holidays).reduce((arr, year) => {
          arr.push(...Object.values(holidays[year]).map((date) => `${date}-${year}`));

          return arr;
        }, []),
        holidayFormat: "MM-DD-YYYY",
      });

      Sentry.setTag("company-id", location.COMPANY_ID);
      Sentry.setTag("location-id", location.ID);
      Sentry.setTag("location-name", location.NAME);

      Sentry.setUser({
        username: account.FULL_NAME,
        email: account.EMAIL,
        id: account.ID,
      });

      getStore().dispatch(
        updateUser({
          account,
          employee,
          ranks,
          permissions,
          showDashboardHub,
          userGuideEntries,
        })
      );
      getStore().dispatch(
        updateShop({
          location,
          settings,
          companySettings,
          taxRates,
          locations,
          giftCardImages,
          employees,
          roles,
          departments,
          companyLocations,
          payrollGroup,
          accountingGroup,
          cashAccountGroup,
        })
      );
      getStore().dispatch(loadConversations());
      getStore().dispatch(loadSupply());
      getStore().dispatch(loadMenu());

      setupSocketHandler();

      this.setState({isLoading: false});
    } catch ({error}) {
      if (error === "SELECT_LOCATION") {
        return this.setState({isSwitching: true}, () => {
          setTimeout(() => {
            this.switchModal.open();
          }, 100);
        });
      }

      console.log("WE GOT ERROR?", error);

      this.props.router.navigate("/login");
    }
  }

  render() {
    const {isLoading, isSwitching} = this.state;

    if (isSwitching) {
      return (
        <>
          <SwitchLocationModal stayOpen ref={(e) => (this.switchModal = e)} />

          <Loading />
        </>
      );
    }

    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    }

    return (
      <div>
        <Announcement
          shortLabel="Refer a coffee shop and get $500"
          link="https://support.dripos.com/docs/refer-to-dripos"
          label="Refer a coffee shop and receive a $500 referral bonus!"
        />

        <Header />
        <AlertService />
        <NotificationService />

        <div>{this.props.children}</div>

        <ChatBubble />
      </div>
    );
  }
}

export default withRouter(LayoutIndex);
