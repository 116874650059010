import React, {Component} from "react";
import {
  PageHeadings,
  Tab,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";
import GrayBadge from "../../../../components/badges/gray-badge";
import WarningBadge from "../../../../components/badges/warning-badge";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import EditWhiteLabelInformationModal from "../../../../modals/admin/tools/edit-white-label-information-modal";
import {setupReduxConnection} from "../../../../redux";
import {loadWhiteLabels} from "../../../../redux/admin";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import EditWhiteLabelImageIconModal from "../../../../modals/admin/tools/edit-white-label-image-icon-modal";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import {saveAs} from "file-saver";
import WhiteLabelBadge from "../../../../features/admin/whitelabel/white-label-badge";

class WhiteLabelPage extends Component {
  state = {
    whiteLabel: {},
  };

  async componentDidMount() {
    await this.syncState();
  }

  syncState = async () => {
    const whiteLabel = await request(`whitelabel/${this.props.router.params.UNIQUE_ID}`);

    this.setState({whiteLabel});
  };

  renderInformation() {
    const {
      APP_NAME,
      APP_SUBTITLE,
      APP_DESCRIPTION,
      KEYWORDS,
      MARKETING_URL,
      SUPPORT_URL,
    } = this.state.whiteLabel;

    return (
      <TwoColumnList
        button={{
          label: "Edit",
          onClick: () => this.informationModal.open(this.state.whiteLabel),
        }}
        label={"App Information"}
        data={[
          {label: "Name", value: APP_NAME},
          {label: "Subtitle", value: APP_SUBTITLE},
          {label: "Description", value: APP_DESCRIPTION},
          {label: "Keywords", value: KEYWORDS},
          {label: "Marketing URL", value: MARKETING_URL},
          {label: "Support URL", value: SUPPORT_URL},
        ]}
      />
    );
  }

  renderIcons() {
    const {whiteLabel} = this.state;

    return (
      <TwoColumnList
        button={{
          label: "Edit",
          onClick: () => this.iconModal.open(whiteLabel),
        }}
        label={"App Icons"}
        data={[
          {
            label: "App Icon",
            value: (
              <img
                style={{borderColor: "black", border: 1, width: 200, height: 200}}
                className={"bg-grid"}
                src={getObjectImage(whiteLabel, "APP_ICON", "appicon.png")}
                alt=""
              />
            ),
          },
          {
            label: "Landing Icon",
            value: (
              <img
                style={{borderColor: "black", border: 1, width: 200, height: 200}}
                src={getObjectImage(whiteLabel, "LANDING_ICON", "appicon.png")}
                alt=""
              />
            ),
          },
        ]}
      />
    );
  }

  getActions() {
    const {whiteLabel} = this.state;

    const confirmAction = {
      label: "Confirm App Information & iOS/Android Listings",
      onClick: async () => {
        await showConfirmAlert(
          "Confirm Listings",
          "Are you sure App Store Connect (iOS app/Apple Pay) and Google Play Console is setup according to the Notion guide?"
        ).then(async (res) => {
          await request(`whitelabel/${whiteLabel.UNIQUE_ID}/build`, "POST");
          await this.syncState();
          showSuccessNotification(
            `${whiteLabel.APP_NAME} is building`,
            "Everything will be ready for you in ~20 minutes, and we'll keep you updated over Slack."
          );
        });
      },
    };

    const downloadAndroidAction = {
      label: "Download Android AAB",
      onClick: async () => {
        const signedUrl = await request(`whitelabel/${whiteLabel.UNIQUE_ID}/android`)

        saveAs(signedUrl, whiteLabel.PACKAGE_IDENTIFIER);
      }
    }

    const actions = [];

    if (whiteLabel && ["DRAFT", "FAILED"].includes(whiteLabel.STATUS)) {
      return [confirmAction];
    }

    if(whiteLabel && whiteLabel.STATUS === "READY_FOR_SUBMIT") {
      return [downloadAndroidAction];
    }

    return actions;
  }

  render() {
    const {whiteLabel} = this.state;

    return (
      <div className={"p-6"}>
        <EditWhiteLabelInformationModal
          syncState={this.syncState}
          ref={(e) => (this.informationModal = e)}
        />

        <EditWhiteLabelImageIconModal
          syncState={this.syncState}
          ref={(e) => (this.iconModal = e)}
        />

        <PageHeadings label={"White-Label App"} buttons={this.getActions()} />

        <TwoColumnList
          label={"App Status"}
          data={[
            {
              label: "Package",
              value: `co.frostbyte.${whiteLabel?.PACKAGE_IDENTIFIER}`,
            },
            {
              label: "Status",
              value:  <WhiteLabelBadge status={whiteLabel.STATUS} />,
            },
          ]}
        />

        <Tab
          data={[
            {label: "Information", id: "information"},
            {label: "Icons", id: "icons"},
          ]}
        >
          {(id) => {
            if (id === "information") {
              return this.renderInformation();
            }
            if (id === "icons") {
              return this.renderIcons();
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["admin"])(withRouter(WhiteLabelPage));
