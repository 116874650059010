import {request} from "../utils/request";
import {VendorRequests} from "../utils/request-helpers/supply-chain/supply-chain-requests";

const defaultState = {
    whiteLabels: null,
};

const UPDATE_WHITE_LABELS = "UPDATE_WHITE_LABELS";

export function updateWhiteLabels(payload) {
    return {type: UPDATE_WHITE_LABELS, payload};
}

export function loadWhiteLabels() {
    return async (dispatch, getState) => {
        const whiteLabels = await request("whitelabel");

        dispatch(updateWhiteLabels(whiteLabels));
    };
}

export const adminReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_WHITE_LABELS:
            return {...state, whiteLabels: payload};
    }
};
